import {Show, useContext} from 'solid-js'
import {i18nProviderContext} from './i18nProviderContext'
import {defineMessages} from '@formatjs/intl'
import {locales} from 'client-translations'
import {useLocale} from './useLocale'
import {useConfig} from '../components'
import {isEmpty} from 'lodash'
import {PRODUCT_FREQUENCY} from '../../../config'
import moment from 'moment'

export const useI18n = () => {
    const {DAY, WEEK, MONTH, YEAR} = PRODUCT_FREQUENCY

    const context = useContext(i18nProviderContext)
    const locale = useLocale()
    const {getConfig} = useConfig()
    const utcOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC',
    }
    const currencies = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        JPY: '¥',
        AUD: '$',
        CAD: '$',
    }

    function isValidDate(date) {
        const parsedDate = new Date(date)
        return !isNaN(parsedDate.getTime())
    }

    return {
        defineMessages: (messages: any) => defineMessages(messages),

        formatMessage: (id: any, values?: Record<string, any>) => context?.formatMessage(id, values),

        formatCurrency: (value: number, options?: any) => {
            options.currency = options?.currency || getConfig().defaultCurrency
            const formatted: any = context?.formatNumber(value || 0, {style: 'currency', ...options})
            if (options?.isCurrencySymbolSuperscript) {
                let currencySymbol = currencies?.[options.currency]
                if (formatted.includes(currencySymbol)) {
                    const amountPart = `${formatted.split(currencySymbol)?.[0]}${formatted.split(currencySymbol)?.[1]}`
                    let isNegative = parseFloat(amountPart) < 0
                    return (
                        <>
                            <Show when={isNegative}>
                                <span>-</span>
                            </Show>
                            <sup style={{'font-size': options?.superFontSize, 'vertical-align': 'text-top'}}>
                                {currencySymbol}
                            </sup>
                            {amountPart.startsWith('-') ? amountPart.slice(1) : amountPart}
                        </>
                    )
                }
            }
            return formatted
        },

        formatNumber: (value: number, options?: any) => context?.formatNumber(value, options),

        formatString: (stringKey: string) => {
            return locales[locale()]?.messages?.[stringKey]
        },

        formatStringWithArgs: (stringKey: string, args: Record<string, any>) => {
            const msg = locales[locale()]?.messages?.[stringKey]
            return msg.replace(/\$\{(.*?)\}/g, (_, key) => args[key] || '')
        },

        formatNotificationStringWithArgs: (stringKey: string, args: Record<string, any>) => {
            const notifications = locales[locale()]?.messages?.notification
            const msg = notifications?.[stringKey]
            return msg.replace(/\$\{(.*?)\}/g, (_, key) => args[key] || '')
        },
        formatDate: ({
            date,
            format = 'M/DD/YYYY, hh:mm A (UTC)',
            setTimeToZero = false,
            setTimeToLast = false,
            setTimeToLastOfPreviousDay = false,
        }) => {
            date = moment.utc(date)
            if (setTimeToZero) {
                return date.set({hour: 0, minute: 0, second: 0, millisecond: 0}).format(format)
            }
            if (setTimeToLast) {
                return date.set({hour: 23, minute: 59, second: 0, millisecond: 0}).format(format)
            }
            if (setTimeToLastOfPreviousDay) {
                return date.subtract(1, 'days').set({hour: 23, minute: 59, second: 0, millisecond: 0}).format(format)
            }
            return date.format(format)
        },
        adjustDateByPeriod: (date, quantity, type) => {
            let momentDate = moment.utc(date)
            switch (type?.toLowerCase()) {
                case DAY:
                    momentDate.add(quantity, 'days') // Add days to the moment object
                    break

                case WEEK:
                    momentDate.add(quantity, 'weeks') // Add weeks to the moment object
                    break

                case MONTH:
                    momentDate.add(quantity, 'months') // Add months to the moment object
                    break

                case YEAR:
                    momentDate.add(quantity, 'years') // Add years to the moment object
                    break

                default:
                    return null
            }

            return momentDate
        },
        isPastDate: oldDate => {
            return moment(oldDate).isBefore(moment(), 'minute')
        },
    }
}
