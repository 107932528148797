import {batch, createEffect, createSignal, on, onCleanup, onMount, Show} from 'solid-js'
import {createStore, reconcile} from 'solid-js/store'
import ReduxContext from './ReduxContext'
import {getStore} from '../appStore'
import {useConfig} from '../../components'

export interface StoreOptions {
    configureStoreFactory: () => any
    voltApi: any
}

export interface ReduxProviderProps extends StoreOptions {
    children: any
}

export const ReduxProvider = (props: ReduxProviderProps) => {
    const config = useConfig()

    const [store, setStore] = createSignal<any>(undefined)
    const [unsubscribe, setUnsubscribe] = createSignal()
    const [state, setState] = createStore<any>(undefined)

    onCleanup(() => unsubscribe())

    onMount(() => {
        getStore(props.configureStoreFactory, config.getConfig(), props.voltApi).then((res: any) => {
            batch(() => {
                setStore(res)
                setUnsubscribe(() =>
                    res.subscribe(() => {
                        setState(reconcile(res.getState()))
                    })
                )
                setState(res.getState())
            })
        })
    })
    createEffect(
        on([() => store()], () => {
            setState(store()?.getState())
        })
    )

    return (
        <Show when={state && store()}>
            <ReduxContext.Provider
                value={{
                    store: store(),
                    state,
                }}
            >
                {props.children}
            </ReduxContext.Provider>
        </Show>
    )
}
