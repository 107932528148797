import UAParser from 'ua-parser-js'
import {getDeviceInfo} from './getDeviceInfo'
import {v4 as uuidv4} from 'uuid'

const isLGWebOS = (): boolean => {
    return !!(window as any)?.['PalmSystem']
}

const isSamsungTizen = (): boolean => {
    return !!(window as any)?.['tizen']
}

// @todo
// Not sure if this list of userAgent is specific for laptop browser...
const isLaptopBrowser = () => {
    return (
        navigator &&
        navigator.userAgent &&
        ['OPR', 'Edge', 'MSIE', 'Opera', 'Safari', 'Chrome', 'Trident', 'Firefox'].some(n =>
            navigator.userAgent.includes(n)
        )
    )
}

const isHisensePlateform = () => {
    return navigator.userAgent.includes('Hisense') || navigator.userAgent.includes('VIDAA')
}

/**
 *
 * @param {'webos' | 'laptop' | 'tizen' | 'hisense'} platformName
 * @returns {boolean}
 */
export const isPlatform = (platformName: any): boolean => {
    switch (platformName) {
        case 'laptop':
            return <boolean>isLaptopBrowser()
        case 'webos':
            return isLGWebOS()
        case 'tizen':
            return isSamsungTizen()
        case 'hisense':
            return isHisensePlateform()
        default:
            return false
    }
}

export const getChromiumVersion = (): number => {
    const info = UAParser(window.navigator.userAgent)
    return Number(info?.browser?.major)
}

export const isLegacyBrowser = () => {
    return getChromiumVersion() < 53 // version of webOS 4 (2018)
}

export const getBrowserName = (voltApi: any) => {
    const userAgent = navigator.userAgent

    switch (true) {
        case userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1:
            return voltApi.Constants.platform.chrome
        case userAgent.indexOf('Edg') > -1:
            return voltApi.Constants.platform.edgeChromium
        case userAgent.indexOf('Chrome') > -1:
            return voltApi.Constants.platform.chrome
        case userAgent.indexOf('Safari') > -1:
            return voltApi.Constants.platform.safari
        case userAgent.indexOf('Firefox') > -1:
            return voltApi.Constants.platform.firefox
        default:
            return 'Unknown'
    }
}

export function getUniqueDeviceId(): string {
    if (!localStorage) {
        console.warn('Cannot store profile ID. localStorage is not supported.')
        return ''
    }
    const DEVICE_UUID_KEY = '___DEVICE_UUID___'
    let _deviceUuid = localStorage.getItem(DEVICE_UUID_KEY)
    if (!_deviceUuid) {
        const uniqueId = uuidv4()
        localStorage.setItem(DEVICE_UUID_KEY, uniqueId)
        return uniqueId
    }

    return _deviceUuid
}

export const getDeviceData = () => {
    const deviceName = getDeviceInfo().client?.name ?? 'Chrome'
    const deviceVersion = getDeviceInfo().client?.version ?? 'Chrome'
    const deviceOS = getDeviceInfo().os?.name ?? 'browser'
    const deviceId = getUniqueDeviceId()

    return {
        manufacturer: navigator.appCodeName, // TODO deprecated
        brand: navigator.appName, // TODO deprecated
        model: deviceVersion,
        screenDimensions: {
            height: window.screen.height,
            width: window.screen.width,
        },
        deviceOS: deviceOS,
        deviceName: deviceName,
        deviceNickName: deviceName,
        deviceId: deviceId,
        uniqueDeviceId: deviceId,
    }
}

export const isSafari = () => {
    if (navigator.userAgent.indexOf('Chrome') > -1) return false
    return navigator.userAgent.indexOf('Safari') > -1
}

export const getDeviceType = () => {
    var userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.indexOf('mobile') > -1) {
        return 'Smartphone' // Phone
    } else if (userAgent.indexOf('tablet') > -1) {
        return 'Tablet'
    } else {
        return 'Desktop'
    }
}
